import { useState } from 'react'

const Toast = (props) => {
  const [toggle, setToggle] = useState(true)

  const closeToast = () => {
    setToggle(false)
    setTimeout(() => {
      props.onClose()
    }, 500)
  }

  const renderSwitch = (param) => {
    switch (param) {
      case -1:
        return 'error'
      case 0:
        return 'warning'
      case 1:
        return 'success'
      default:
        return 'default'
    }
  }
  return (
    <div className={(renderSwitch(props.color)) + ' ' + (toggle ? 'slide-up' : 'slide-down') + ' toast d-flex flex-column p-3 pe-3'}>
      <div className="d-flex align-items-center mb-3">
        <span className="fw-bold flex-grow-1">{props.title}</span>
        <button onClick={() => closeToast() } className="btn btn-sm p-0 fs-3 lh-1">&times;</button>
      </div>
      <div>
        { props.message }
      </div>
    </div>
  )
}
export default Toast
