import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Toast from '../../common/toast'
import { get, post } from '../../../util'

const EditTalent = () => {
  const params = useParams()

  // const [talent, setTalent] = useState([])
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [skills, setSkills] = useState('')
  const [noticePeriod, setNoticePeriod] = useState('0')
  const [experience, setExperience] = useState('')
  const [currentLocation, setCurrentLocation] = useState('')
  const [currentCompany, setCurrentCompany] = useState('')
  const [currentCTC, setCurrentCTC] = useState('')
  const [qualification, setQualification] = useState('')
  // const [preferredLocations, setPreferredLocations] = useState('')
  const [resume, setResume] = useState('')

  const [toastTitle, setToastTitle] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastColor] = useState(0)
  const [showToast, setShowToast] = useState(false)

  // const [update, setUpdate] = useState(false)

  useEffect(() => {
    if (params.id) {
      getTalent(`${process.env.REACT_APP_TALENT_GET}?` + new URLSearchParams({ id: params.id })).then(data => {
        // setTalent(data)
        setId(data.id)
        setName(data.name)
        setEmail(data.email)
        setMobile(data.mobile)
        setSkills(data.skills)
        setQualification(data.qualification)
        setCurrentLocation(data.currentLocation)
        setNoticePeriod(data.noticePeriod)
        setCurrentCompany(data.currentCompany)
        setCurrentCTC(data.currentCTC)
        setExperience(data.experience)
      }).catch(error =>
        console.error(`Get talent error: ${error}`)
      )
    }
  }, [])

  const getTalent = async (url) => {
    const response = await get(url)
    return response.json()
  }

  const editTalent = async (url, data) => {
    const response = await post(url, data)
    if (response.ok) {
      setToastColor(1)
      setToastTitle('Success')
      setToastMessage('Talent update')
      setShowToast(true)
      return response.json()
    } else {
      setToastColor(-1)
      setToastTitle(`Error (${response.status})`)
      setToastMessage(`Details: ${response.statusText}`)
      setShowToast(true)
    }
  }

  const handleEditTalent = (e) => {
    setShowToast(false)
    e.preventDefault()
    const payload = {
      id: id,
      name: name,
      email: email,
      mobile: mobile,
      skills: skills,
      noticePeriod: noticePeriod,
      experience: experience,
      currentCompany: currentCompany,
      currentCTC: currentCTC,
      currentLocation: currentLocation,
      qualification: qualification
    }

    editTalent(params.id ? process.env.REACT_APP_TALENT_UPDATE : process.env.REACT_APP_TALENT_ADD, payload).then(data => {
    }).catch(error => {
      console.error(`Add talent Error: ${error}`)
    })
  }

  const handleFileInput = (e) => {
    const file = e.target.files[0]
    console.log('file size: ', file.size)
    if (file.size > 2000000) {
      console.error('File size cannot exceed more than 2MB')
    } else {
      submitForm(file)
    }
  }

  const submitForm = (file) => {
    setEmail('')
    setMobile('')
    setSkills('')

    const formData = new FormData()

    formData.append('formFile', file)
    formData.append('fileName', file.name)

    uploadResume(process.env.REACT_APP_PROFILE_UPLOAD, formData).then(data => {
      setEmail(data.email)
      setMobile(data.mobile)
      setSkills(data.skills)
    }).catch(error =>
      console.error(`Upload Error: ${error}`)
    )
  }

  const uploadResume = async (url, data) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
      },
      body: data
    })
    return response.json()
  }

  return (
    <div>
      {showToast && <Toast title={toastTitle} message={toastMessage} color={toastColor} onClose={() => { setShowToast(false) }} />}
      <section className="hero-client p-5">
        <div className="container d-flex">
          <div className="left-hero">
            <h1 className="fw-bold">Edit <span className="text-theme">Resume</span></h1>
            <h5>You can draft and publish a job</h5>
          </div>
          <div className="flex-grow-1 text-end">
            <Link className="btn btn-theme" to={'../recruiter/talents'}>Talents</Link>
          </div>
        </div>
      </section>
      <section className="container shadow p-5 my-5 bg-white">
        <form onSubmit={(e) => handleEditTalent(e)}>
          <div className="mb-3">
            <button type="button" className="btn btn-outline-theme input-file me-3">
              <span className="me-5">Upload resume</span>
              <svg viewBox="0 0 32 32" height="24" fill="#fff">
                <g>
                  <path d="M25,14a1,1,0,0,1-.71-.29L16,5.41l-8.29,8.3a1,1,0,0,1-1.42-1.42l9-9a1,1,0,0,1,1.42,0l9,9a1,1,0,0,1,0,1.42A1,1,0,0,1,25,14Z" />
                  <path d="M16,29a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V28A1,1,0,0,1,16,29Z" />
                </g>
              </svg>
              <input type="file" id="fileResume" name="resume" onChange={handleFileInput} accept=".doc,.docx,.pdf,.rtf,.txt" />
            </button>
            <small className="text-muted">Upload resume will fetch email, mobile number and technical skills if any, rest items you can fill from resume content below.</small>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label id="lblName" htmlFor="txtName" className="form-label">Name</label>
              <input type="text" className="form-control" id="txtName" aria-describedby="lblName" value={name} onChange={(e) => { setName(e.target.value) }} />
            </div>
            <div className="col-md-4">
              <label id="lblEmail" htmlFor="txtEmail" className="form-label">Email</label>
              <input type="text" className="form-control" id="txtEmail" aria-describedby="lblEmail" required value={email} onChange={(e) => { setEmail(e.target.value) }} />
            </div>
            <div className="col-md-4">
              <label id="lblMobile" htmlFor="txtMobile" className="form-label">Mobile</label>
              <input type="text" className="form-control" id="txtMobile" aria-describedby="lblJobLocation" value={mobile} onChange={(e) => { setMobile(e.target.value) }} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <label id="lblSkills" htmlFor="txtSkills" className="form-label">Skills</label>
              <input type="text" className="form-control" id="txtSkills" aria-describedby="lblSkills" value={skills} onChange={(e) => { setSkills(e.target.value) }} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Notice Period</label>
              <select className="form-select" value={noticePeriod} onChange={(e) => { setNoticePeriod(e.target.value) }}>
                <option value="0">Immediate</option>
                <option value="15">15 days</option>
                <option value="30">30 days</option>
                <option value="45">45 days</option>
                <option value="60">60 days</option>
                <option value="90">90 days</option>
              </select>
            </div>
            <div className="col-md-3">
              <label id="lblExperience" htmlFor="txtExperience" className="form-label">Experience</label>
              <input type="text" className="form-control" id="txtExperience" aria-describedby="lblExperience" value={experience} onChange={(e) => { setExperience(e.target.value) }} />
            </div>
            <div className="col-md-3">
              <label id="lblQualification" htmlFor="txtQualification" className="form-label">Qualification</label>
              <input type="text" className="form-control" id="txtQualification" aria-describedby="lblQualification" value={qualification} onChange={(e) => { setQualification(e.target.value) }} />
            </div>
            <div className="col-md-3">
              <label id="lblCurrentLocation" htmlFor="txtCurrentLocation" className="form-label">Current Location</label>
              <input type="text" className="form-control" id="txtCurrentLocation" aria-describedby="lblCurrentLocation" value={currentLocation} onChange={(e) => { setCurrentLocation(e.target.value) }} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label id="lblCurrentCompany" htmlFor="txtCurrentCompany" className="form-label">Current Company</label>
              <input type="text" className="form-control" id="txtCurrentCompany" aria-describedby="lblCurrentCompany" value={currentCompany} onChange={(e) => { setCurrentCompany(e.target.value) }} />
            </div>
            <div className="col-md-6">
              <label id="lblCurrentCTC" htmlFor="txtCurrentCTC" className="form-label">Current CTC</label>
              <input type="text" className="form-control" id="txtCurrentCTC" aria-describedby="lblCurrentCTC" value={currentCTC} onChange={(e) => { setCurrentCTC(e.target.value) }} />
            </div>
          </div>
          <div className="mb-3">
            <label id="lblResume" htmlFor="txtResume" className="form-label">Resume Content</label>
            <textarea type="text" className="form-control" id="txtResume" aria-describedby="lblResume" value={resume} onChange={(e) => { setResume(e.target.value) }} style={{ minHeight: '10rem' }}></textarea>
          </div>
          <div className="form-buttons mt-5">
            <Link className="btn btn-primary me-4" to={'../recruiter/talents'}>Talents</Link>
            <button className="btn btn-theme" to="upload-resume">
              Submit
            </button>
          </div>
        </form>
      </section>
    </div>
  )
}
export default EditTalent
