import './header.css'
import Nav from '../nav/nav'

const Header = () => {
    return (
        <header className="bg-white sticky-top">
            <div className="py-2 py-lg-4">
                <Nav/>
            </div>
        </header>
    )
}
export default Header
