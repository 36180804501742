import { Route, Routes } from 'react-router-dom'
import Layout from './components/shared/layout/layout'
import Home from './components/home/home'
import Jobs from './components/job/jobs'
import Job from './components/job/job'
import Contact from './components/contact/contact'
import Container from './components/shared/layout/container'
import UploadResume from './components/uploadresume/uploadresume'
import RLayout from './components/recruiter/rlayout/rlayout'
 import RHome from './components/recruiter/rhome/rhome'
import PostJob from './components/recruiter/postjob/postjob'
import Login from './components/common/login'
import Talents from './components/recruiter/talents/talents'
import TalentProfile from './components/recruiter/talents/profile'
import Clients from './components/recruiter/clients/clients'
import Client from './components/recruiter/clients/client'
import EditTalent from './components/recruiter/talents/editTalent'
import Users from './components/recruiter/users/users'
import CreateUser from './components/recruiter/users/createUser'

function App () {
  return (
    <Routes>
      <Route element={<Container />}>
        <Route path='/contact' element={<Contact />} />
        <Route path="*" component={<Home />} status={404} />
      </Route>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/upload-resume" element={<UploadResume />} />
        <Route path="jobs" element={<Jobs />} />
        <Route path="job/:id" element={<Job />} />
      </Route>
      <Route element={<RLayout />}>
        <Route path="/recruiter" element={<RHome />} />
        <Route path="/recruiter/talents" element={<Talents />} />
        <Route path="/recruiter/clients" element={<Clients />} />
        <Route path="/recruiter/client/:id" element={<Client />} />
        <Route path="/recruiter/talent/:id" element={<TalentProfile />} />
        <Route path="/recruiter/talent/edit/:id" element={<EditTalent />} />
        <Route path="/recruiter/talent/add" element={<EditTalent />} />
        <Route path="/recruiter/job/edit/:id?" element={<PostJob />} />
        <Route path="/recruiter/post-job" element={<PostJob />} />
        <Route path="/recruiter/profiles" element={<Talents />} />
        <Route path="posted-jobs" element={<Jobs />} />
        <Route path="job/:id" element={<Job />} />
        <Route path="/recruiter/users" element={<Users />} />
        <Route path="/recruiter/users/create" element={<CreateUser />} />
        <Route path="/recruiter/user/edit/:id" element={<CreateUser />} />
      </Route>
    </Routes>

  )
}

export default App
