import './footer.css'

const Footer = () => {
  return (
    <footer className="py-3 py-lg-5 px-lg-5">
      <div className="container">
        <div className="d-flex flex-wrap">
          <div className="flex-grow-1">
            <a className="navbar-brand" href='/'>
              egHRM.com
            </a>
            <div className="mt-3 ps-1">
              Izeetek, 86/80, 4th Floor, SGR Plaza,
              Old Airport Road, Marathahalli,
              Bengaluru - 560037, Karnataka, India
              <div className="d-flex justify-content-between mt-4">
                <div>
                  <h6>Phone</h6>
                  <a className="text-white" href="tel:+917406272111">+91 (740) 627 2111</a>
                </div>
                <div className="px-4">
                  <h6>Email</h6>
                  <a className="text-white me-3" href="mailto:info@izeetek.com">info@izeetek.com</a>
                  <a className="text-white" href="mailto:contact@izeetek.com">contact@izeetek.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-4 mt-md-0">
            <div className="px-3 px-md-5">
              <h6 className="mb-3">eghrm.com</h6>
              <div className="d-flex flex-column ps-1">
                <a href='privacy'>Privacy</a>
                <a href='terms'>Terms</a>
                <a href='policies'>Policies</a>
                <a href='feedback'>Feedback</a>
                <a href="blogs">Blogs</a>
              </div>
            </div>
            <div className="px-3 px-md-5">
              <h6 className="mb-3">Job Seeker</h6>
              <div className="d-flex flex-column ps-1">
                <a href='resume-builder'>Resume Builder</a>
                <a href="salaries">Salaries</a>
                <a href="salary-calculator">Salary Calculator</a>
              </div>
            </div>
            <div className="px-3 px-md-5">
              <h6 className="mb-3">Recruiter</h6>
              <div className="d-flex flex-column ps-1">
                <a href='post-jobs'>Post Jobs</a>
                <a href='search-resume'>Search Resume</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
