import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../../redux/slices/commonSlice'
import { post } from '../../../util'

const Nav = () => {
  const [loggedin, setLoggedin] = useState(false)
  const { authorized } = useSelector((state) => state.common)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    authorized ? setLoggedin(true) : setLoggedin(false)
    checkAuthStatus()
  }, [authorized])

  const checkAuthStatus = async () => {
    await post(process.env.REACT_APP_AUTH_STATUS).then(data => {
      if (data && data.status === 200) {
        setLoggedin(true)
      }
    }).catch(error =>
      console.error(`auth status: ${error}`)
    )
  }

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <div className='container'>
      <nav className='navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white'>
        <div className='d-flex'>
          <Link className="navbar-brand" to="/">
            egHRM
          </Link>
          <Link className="nav-link" to="/jobs">Jobs</Link>
          <Link className="nav-link" to="/recruiter/talents">Talents</Link>
          {/* <Link className="nav-link" to="/recruiter/clients">Clients</Link> */}
          {/* <Link className="nav-link" to="/services">Services</Link> */}
        </div>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse d-sm-inline-flex justify-content-between">
          <ul className="navbar-nav flex-grow-1">
            <li className="nav-item">

              {/* <div className="input-group"> */}
              {/*  <input type="search" placeholder="What're you searching for?" aria-describedby="button-addon5" className="form-control" /> */}
              {/*  <div className="input-group-append"> */}
              {/*    <button id="button-addon5" type="button" className="btn btn-theme"> */}
              {/*      <svg width="24" height="24" style={{ enableBackground: 'new 0 0 64 64' }} fill="#fff" version="1.1" viewBox="0 0 64 64"> */}
              {/*        <g transform="translate(30.000000, 230.000000)"> */}
              {/*          <path d="M-2.3-182.9c-10.7,0-19.5-8.7-19.5-19.5c0-10.7,8.7-19.5,19.5-19.5s19.5,8.7,19.5,19.5  C17.1-191.6,8.4-182.9-2.3-182.9L-2.3-182.9z M-2.3-219c-9.2,0-16.7,7.5-16.7,16.7c0,9.2,7.5,16.7,16.7,16.7s16.7-7.5,16.7-16.7 C14.3-211.5,6.8-219-2.3-219L-2.3-219z" id="Fill-1" /> */}
              {/*          <polyline points="23.7,-174.2 10.1,-187.7 12.3,-189.9 25.8,-176.3 23.7,-174.2 " /> */}
              {/*        </g> */}
              {/*      </svg> */}
              {/*    </button> */}
              {/*  </div> */}
              {/* </div> */}
            </li>
          </ul>
          <ul className="navbar-nav align-items-center">
            <li className="nav-item">
              <Link className="nav-link" to="/recruiter">Recruiter</Link>
            </li>
            <li className="nav-item">
              {loggedin &&
                <button className="btn btn-theme btn-sm" onClick={() => handleLogout() }>Log out</button>
              }
              {!loggedin &&
                <Link className="btn btn-theme btn-sm" to="/login">Log in</Link>
              }
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Nav
