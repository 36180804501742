import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { get } from '../../../util'
import Toast from '../../common/toast'

const Client = () => {
  const params = useParams()
  const [jobs, setJobs] = useState([])

  const [toastTitle, setToastTitle] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastColor] = useState(0)
  const [showToast, setShowToast] = useState(false)

  useEffect(() => {
    listJobs(`${process.env.REACT_APP_JOB_LIST}?` + new URLSearchParams({ clientid: params.id })).then(data => {
      setJobs(data)
    }).catch(error =>
      console.error(`List Error: ${error}`)
    )
  }, [])

  const listJobs = async (url) => {
    const response = await get(url)
    if (response.ok) {
      return response.json()
    } else {
      if (response.status === 401) {
        setToastTitle('Unauthorized Access')
        setToastMessage('Please log in to authorize.')
      } else {
        setToastTitle(`Error ${response.status}`)
        setToastMessage('Server error')
      }
      setToastColor(-1)
      setShowToast(true)
    }
  }

  return (
    <div className="container">
      {showToast && <Toast title={toastTitle} message={toastMessage} color={toastColor} onClose={() => { setShowToast(false) }} />}
      <h1>{jobs?.length > 0 ? jobs[0].client : 'Client'}</h1>
      <h3>Jobs</h3>
      {
        jobs?.map((item) =>
        (<div key={item.id} className="card shadow mb-4">
          <div className="p-4">
            <div>
              <Link to={`../job/${item.id}`} className="text-theme h5 text-decoration-none">{item.title}</Link>
            </div>
            <small className="me-3">Experience: {item.experience}</small>
            <small className="me-3">Openings: {item.positions}</small>
            <small className="me-3">Sortlisted: {item.sortlisted}</small>
          </div>
          <div className="px-4 py-3 border-top d-flex align-items-center justify-content-between">
            <div className="flex-grow-1">
              <small className="text-muted">14 days ago</small>
            </div>
            <Link type="button" className="btn btn-sm btn-theme mx-3" to={`../recruiter/talents?jid=${item.id}`}>Find Talents{item.counts > 0 && ` (${item.counts})`}</Link>
            <Link type="button" className="btn btn-sm btn-theme" to={`../recruiter/job/edit/${item.id}`}>Edit</Link>
          </div>
        </div>)
        )
      }
    </div>
  )
}
export default Client
