import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { get } from '../../../util'

const TalentProfile = () => {
  const params = useParams()

  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [skills, setSkills] = useState('')
  const [noticePeriod, setNoticePeriod] = useState(0)
  const [experience, setExperience] = useState('')
  const [currentLocation, setCurrentLocation] = useState('')
  const [currentCompany, setCurrentCompany] = useState('')
  const [currentCTC, setCurrentCTC] = useState('')
  const [qualification, setQualification] = useState('')

  useEffect(() => {
    getTalent(`${process.env.REACT_APP_TALENT_GET}?` + new URLSearchParams({ id: params.id })).then(data => {
      // setTalent(data)
      setId(data.id)
      setName(data.name)
      setEmail(data.email)
      setMobile(data.mobile)
      setSkills(data.skills)
      setQualification(data.qualification)
      setCurrentLocation(data.currentLocation)
      setNoticePeriod(data.noticePeriod)
      setCurrentCompany(data.currentCompany)
      setCurrentCTC(data.currentCTC)
      setExperience(data.experience)
    }).catch(error =>
      console.error(`Get talent error: ${error}`)
    )
  }, [])

  const getTalent = async (url) => {
    const response = await get(url)
    return response.json()
  }

  return (
    <div>
      <section className="hero p-3 p-md-5">
        <div className="container d-flex">
          <div className="left-hero">
            <h1 className="fw-bold text-theme">Talent Details</h1>
          </div>
        </div>
      </section>
      <section className="container mb-4 resume-form">
        <form>
          <div className="d-flex mb-3">
            <div className="col-md-9 pe-4 flex-grow-1">
              <div className="bg-white card">
                {
                  <div className="mb-4">
                    <div className="p-4">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h4 className="text-theme">{name}</h4>
                        </div>
                        <div>
                          <Link className="btn btn-sm btn-theme" to={`../recruiter/talent/edit/${id}`}>Edit</Link>
                        </div>
                      </div>
                      <h6 className="text-muted">{email}</h6>
                      <h6 className="text-muted">{mobile}</h6>
                      <div>
                        {experience &&
                          <div className="my-3">
                            <div className="fw-bold mt-2 text-theme">Experience</div>
                            {experience}
                          </div>
                        }
                        {noticePeriod &&
                          <div className="my-3">
                            <div className="fw-bold mt-2 text-theme">Notice Period</div>
                            {noticePeriod} days
                          </div>
                        }
                        {skills &&
                          <div className="my-3">
                            <div className="fw-bold mt-2 text-theme">Skills</div>
                            {skills}
                          </div>
                        }
                        {currentLocation &&
                          <div className="my-3">
                            <div className="fw-bold mt-2 text-theme">Current Location</div>
                            {currentLocation}
                          </div>
                        }
                        {currentCTC &&
                          <div className="my-3">
                            <div className="fw-bold mt-2 text-theme">Current CTC</div>
                            {currentCTC}
                          </div>
                        }
                        {currentCompany &&
                          <div className="my-3">
                            <div className="fw-bold mt-2 text-theme">Current Company</div>
                            {currentCompany}
                          </div>
                        }
                        {qualification &&
                          <div className="my-3">
                            <div className="fw-bold mt-2 text-theme">Qualification</div>
                            {qualification}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="col-md-3 card p-3 bg-white rounded">
              Similar Profiles
            </div>
          </div>
        </form>
      </section>
    </div>
  )
}
export default TalentProfile
