import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get, post } from '../../../util'
import Toast from '../../common/toast'

const CreateUser = () => {
  const params = useParams()

  const [id, setId] = useState(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [roles, setRoles] = useState([])
  const [role, setRole] = useState(null)

  const [toastTitle, setToastTitle] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastColor] = useState(0)
  const [showToast, setShowToast] = useState(false)
  useEffect(() => {
    listRoles()
    // handleCreateRole();
    setId(null)
    if (params.id) {
      setId(params.id)
      getUser(`${process.env.REACT_APP_USER_GET}?` + new URLSearchParams({ id: params.id })).then(data => {
        setFirstName(data.firstName)
        setLastName(data.lastName)
        setPhoneNumber(data.phoneNumber)
        setEmail(data.email)
      }).catch(error =>
        console.error(`Get user error: ${error}`)
      )
    }
  }, [])

  const getUser = async (url) => {
    const response = await get(url)
    return response.json()
  }

  const createUser = async (url, data) => {
    const response = await post(url, data)
    return response.json()
  }

  const handleAddUser = (e) => {
    e.preventDefault()
    createUser(process.env.REACT_APP_USER_ADD, { id: id, email: email, password: password, firstName: firstName, lastName: lastName, phoneNumber: phoneNumber, role: role }).then(data => {
      if (data) {
        setToastTitle('Success')
        setToastMessage('User created successfully')
        setToastColor(1)
        setShowToast(true)
      } else {
        setToastTitle('Error creating user')
        setToastColor(-1)
        setShowToast(true)
      }
    }).catch(error => {
      console.error(`user create error: ${error}`)
      setToastTitle('Error creating user')
      setToastColor(-1)
      setShowToast(true)
    }
    )
  }

  const getRoles = async (url, data) => {
    const response = await post(url, data)
    return response.json()
  }

  const listRoles = () => {
    getRoles(process.env.REACT_APP_USER_ROLES).then(data => {
      setRoles(data)
    }).catch(error =>
      console.error(`User roles list error: ${error}`)
    )
  }

  // const createRole = async (url, data) => {
  //  const response = await post(url, data)
  //  return response.json()
  // }

  // const handleCreateRole = () => {
  //  createRole(process.env.REACT_APP_USER_ROLE_ADD, { name: 'Trainee' }).then(data => {
  //    setRole(data)
  //  }).catch(error =>
  //    console.error(`User role create error: ${error}`)
  //  )
  // }

  return (
    <div>
      {showToast && <Toast title={toastTitle} message={toastMessage} color={toastColor} onClose={() => { setShowToast(false) }} />}

      <section className="hero p-3 p-md-5">
        <div className="container d-flex">
          <div className="left-hero">
            <h1 className="fw-bold text-theme">Create User</h1>
            <h5>Add, edit and manage Users</h5>
          </div>
        </div>
      </section>
      <section className="container shadow mb-4 resume-form" style={{ backgroundColor: '#fafafa' }}>
        <div className="d-flex mb-3 p-4">
          <form className="col-md-8" onSubmit={(e) => { handleAddUser(e) }}>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="txtFirstName" className="form-label">First Name</label>
                <input value={firstName} onChange={(e) => { setFirstName(e.target.value) }} type="text" className="form-control" id="txtFirstName" aria-describedby="firstNameHelp" />
                <div id="firstNameHelp" className="form-text">Please enter your first name.</div>
              </div>
              <div className="col-md-6">
                <label htmlFor="txtLastName" className="form-label">Last Name</label>
                <input value={lastName} onChange={(e) => { setLastName(e.target.value) }} type="text" className="form-control" id="txtLastName" aria-describedby="lastNameHelp" />
                <div id="lastNameHelp" className="form-text">Please enter your last name.</div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="txtSignupEmail" className="form-label">Email</label>
                <input value={email} onChange={(e) => { setEmail(e.target.value) }} type="email" className="form-control" id="txtSignupEmail" aria-describedby="emailHelp" required />
                <label htmlFor="txtSignupEmail" className="form-label">Email</label>
                <div id="emailHelp" className="form-text">Enter ueser email</div>
              </div>
              <div className="col-md-6">
                <label htmlFor="txtSignupPassword" className="form-label">Password</label>
                <input onChange={(e) => { setPassword(e.target.value) }} type="password" className="form-control" id="txtSignupPassword" required={!id} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" />
                <div id="passwordHelp" className="form-text">Password should be at lease 6 characters with 1 lower case, 1 upper case and 1 digit</div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="txtSignupPhoneNumber" className="form-label">Mobile</label>
                <input value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} type="tel" className="form-control" id="txtSignupPhoneNumber" aria-describedby="phoneNumberHelp" />
                <div id="phoneNumberHelp" className="form-text">Enter user mobile number</div>
              </div>
              <div className="col-md-6">
                <label htmlFor="ddsRoles" className="form-label">Roles</label>
                <select id="ddsRoles" onChange={(e) => setRole(e.target.value)} className="form-control" aria-describedby="emailHelp">
                  <option value="">Select Role</option>
                  {roles?.map((item, index) => (
                    <option key={ 'roleoption' + index } value={item.name}>{ item.name }</option>
                    ))
                  }
                </select>
                <div id="emailHelp" className="form-text">Assign role to user</div>
              </div>
            </div>
            <div className="mb-3 form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" required />
              <label className="form-check-label" htmlFor="exampleCheck1">I accept terms and condition</label>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
      </section>

    </div>
  )
}
export default CreateUser
