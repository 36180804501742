import { useState, useEffect } from 'react'
import { get } from '../../util'

export const AutoComplete = (props) => {
  const [clients, setClients] = useState([])

  useEffect(() => {
    listClient(process.env.REACT_APP_PARTNER_LIST).then(data => {
      setClients(data)
    }).catch(error =>
      console.error(`List Error: ${error}`)
    )
  }, [])

  const listClient = async (url) => {
    const response = await get(url)
    return response.json()
  }

  useEffect(() => {
    // setClients([{ name: 'Google' }, { name: 'Apple' }, { name: 'Meta' }])
  }, [])

  const selectClient = (client) => {
    props.onClientSelect(client)
  }

  return (
    <div className="card shadow" style={{ maxHeight: '15rem', overflowY: 'auto' }}>
      {
        clients?.map((item, index) =>
        (
          <div key={'client-' + index} className="px-3 py-2 border-bottom" onClick={ () => selectClient(item) }>
            {item.name}
          </div>
        ))
      }
    </div>)
}
