import { useState } from 'react'

const Pagination = (props) => {
  const [currentPage, setCurrentPage] = useState(1)

  const prevPageHandler = () => {
    if (currentPage > 1) {
      props.onPageChange(currentPage - 1)
      setCurrentPage(currentPage - 1)
    }
  }
  const nextPageHandler = () => {
    if (currentPage < props?.totalPage) {
      const cp = currentPage + 1
      setCurrentPage(cp)
      props.onPageChange(cp)
    }
  }

  return (
    <div className="d-flex ms-3">
      <select className="form-select me-3" style={{ minWidth: '7.5rem' }}>
        <option>Show 10</option>
        <option>Show 20</option>
        <option>Show 50</option>
        <option>Show 100</option>
      </select>
      <button type="button" className="btn btn-outline-theme btn-sm me-2" onClick={(e) => prevPageHandler()}>
        <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
          <path d="m4.5 8.5-4-4 4-4" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(7 6)" />
        </svg>
      </button>
      <input className="text-center" style={{ maxWidth: '3rem' }} value={currentPage} onChange={(e) => { setCurrentPage(e.target.value) }} />
      <button type="button" className="btn btn-outline-theme btn-sm ms-2" onClick={(e) => nextPageHandler()}>
        <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
          <path d="m.5 8.5 4-4-4-4" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(9 6)" />
        </svg>
      </button>
    </div>
  )
}
export default Pagination
