import { Link } from 'react-router-dom'
import '../../home/home.scss'
import { useEffect } from 'react'

const RHome = () => {
  useEffect(() => {
    const ctx = document.getElementById('chartSkills')

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['.NET', 'Java', 'React', 'Angular', 'Node', 'Python'],
        datasets: [{
          label: '# of Candidates',
          data: [12, 19, 3, 5, 2, 3],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    })

    const ctxJobs = document.getElementById('chartJobs')

    new Chart(ctxJobs, {
      type: 'line',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [{
          label: '# of Jobs',
          data: [12, 19, 3, 5, 2, 3],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    })
  }, [])

  return (
    <div>
      <section className="hero-client p-5">
        <div className="container d-flex">
          <div className="left-hero">
            <h1 className="fw-bold mb-5"><span className="text-theme">Recruiter</span> Center</h1>
            <Link className="btn btn-theme fw-bold me-3" to="talents">
              <span className="me-4">Talents</span>
              <svg width="32" height="32" fill="#fff" version="1.1" viewBox="0 0 64 64"><g transform="translate(30.000000, 230.000000)"><path d="M-2.3-182.9c-10.7,0-19.5-8.7-19.5-19.5c0-10.7,8.7-19.5,19.5-19.5s19.5,8.7,19.5,19.5  C17.1-191.6,8.4-182.9-2.3-182.9L-2.3-182.9z M-2.3-219c-9.2,0-16.7,7.5-16.7,16.7c0,9.2,7.5,16.7,16.7,16.7s16.7-7.5,16.7-16.7 C14.3-211.5,6.8-219-2.3-219L-2.3-219z" id="Fill-1"></path><polyline points="23.7,-174.2 10.1,-187.7 12.3,-189.9 25.8,-176.3 23.7,-174.2 "></polyline></g></svg>
            </Link>
            <Link className="btn btn-theme fw-bold" to="post-job">
              <span className="me-4">Post a job</span>
              <svg viewBox="0 0 32 32" width="32" fill="#fff">
                <path d="M22,9a1,1,0,0,0,0,1.42l4.6,4.6H3.06a1,1,0,1,0,0,2H26.58L22,21.59A1,1,0,0,0,22,23a1,1,0,0,0,1.41,0l6.36-6.36a.88.88,0,0,0,0-1.27L23.42,9A1,1,0,0,0,22,9Z" />
              </svg>
            </Link>
            <Link className="btn btn-theme fw-bold ms-3" to="users">
              <span className="me-4">Users</span>
              <svg viewBox="0 0 32 32" width="32" fill="#fff">
                <g>
                  <path d="M20,19a5,5,0,1,0-5-5A5,5,0,0,0,20,19Zm0-8a3,3,0,1,1-3,3A3,3,0,0,1,20,11Z" />
                  <path d="M8.94,10a4,4,0,1,0-4-4A4,4,0,0,0,8.94,10Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,8.94,4Z" />
                  <path d="M25,20H15a5,5,0,0,0-5,5v5h2V25a3,3,0,0,1,3-3H25a3,3,0,0,1,3,3v5h2V25A5,5,0,0,0,25,20Z" />
                  <path d="M13,11H6a4,4,0,0,0-4,4v4H4V15a2,2,0,0,1,2-2h7Z" />
                </g>
              </svg>
            </Link>
          </div>
          <div className="right-hero">

          </div>
        </div>
      </section>
      <section className="container d-flex flex-wrap justify-content-between text-center count-cards">
        <div className="card shadow-sm rounded color-2">
          <span className="fs-3">720</span> Resumes
        </div>
        <div className="card shadow-sm rounded color-3">
          <span className="fs-3">42</span> Jobs
        </div>
        <Link className="card shadow-sm rounded text-theme text-decoration-none" to="clients">
          <span className="fs-3">8</span> Clients
        </Link>
        <div className="card shadow-sm rounded color-4">
          <span className="fs-3">125</span> Responses
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6">
            <canvas id="chartSkills"></canvas>
          </div>
          <div className="col-md-6">
            <canvas id="chartJobs"></canvas>
          </div>
        </div>
      </section>
    </div>
  )
}
export default RHome
