import { useState } from 'react'

const UploadResume = () => {
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [skill, setSkill] = useState('')

  const handleFileInput = (e) => {
    const file = e.target.files[0]
    console.log('file size: ', file.size)
    if (file.size > 2000000) {
      console.error('File size cannot exceed more than 2MB')
    } else {
      submitForm(file)
    }
  }

  const submitForm = (file) => {
    setEmail('')
    setMobile('')
    setSkill('')

    const formData = new FormData()

    formData.append('formFile', file)
    formData.append('fileName', file.name)

    uploadResume(process.env.REACT_APP_PROFILE_UPLOAD, formData).then(data => {
      setEmail(data.email)
      setMobile(data.mobile)
      setSkill(data.skills)
    }).catch(error =>
      console.error(`Upload Error: ${error}`)
    )
  }

  const uploadResume = async (url, data) => {
    const response = await fetch(url, {
      method: 'POST',
      body: data
    })
    return response.json()
  }

  return (
    <div>
      <section className="hero p-3 p-md-5">
        <div className="container d-flex">
          <div className="left-hero">
            <h1 className="fw-bold">Upload your <span className="text-theme">resume</span></h1>
            <h5>We will find a suitable job for your profile</h5>
          </div>
        </div>
      </section>
      <section className="container shadow p-5 resume-form bg-white">
        <form>
          <div className="row mb-3">
            <div className="col-md-6">
              <label id="lblFirstName" htmlFor="txtFirstName" className="form-label">First name</label>
              <input type="text" className="form-control" id="txtFirstName" aria-describedby="lblFirstName" />
            </div>
            <div className="col-md-6">
              <label id="lblLastName" htmlFor="txtLasttName" className="form-label">Last name</label>
              <input type="text" className="form-control" id="txtLasttName" aria-describedby="lblLastName" />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={email} onChange={(e) => setEmail(e.target.value)} />
              <div id="emailHelp" className="form-text">You will receive recruiter interest in your email</div>
            </div>
            <div className="col-md-6">
              <label htmlFor="txtInputMobile" className="form-label">Mobile number</label>
              <input type="text" className="form-control" id="txtInputMobile" aria-describedby="mobileHelp" value={mobile} onChange={(e) => setMobile(e.target.value) } />
              <div id="mobileHelp" className="form-text">Recruiter will call your for interview</div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="txtSkills" className="form-label">Sills</label>
              <input type="text" className="form-control" id="txtSkills" value={skill} onChange={(e) => setSkill(e.target.value)} />
            </div>
            <div className="col-md-6">
              <label htmlFor="fileResume" className="form-label">Upload Resume</label>
              <div>
                <button type="button" className="btn btn-outline-theme input-file">
                  <span className="me-5">Attact your resume</span>
                  <svg viewBox="0 0 32 32" height="24" fill="#fff">
                    <g>
                      <path d="M25,14a1,1,0,0,1-.71-.29L16,5.41l-8.29,8.3a1,1,0,0,1-1.42-1.42l9-9a1,1,0,0,1,1.42,0l9,9a1,1,0,0,1,0,1.42A1,1,0,0,1,25,14Z" />
                      <path d="M16,29a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V28A1,1,0,0,1,16,29Z" />
                    </g>
                  </svg>
                  <input type="file" id="fileResume" name="resume" onChange={handleFileInput} accept=".doc,.docx,.pdf,.rtf,.txt" />
                </button>
              </div>
            </div>
          </div>
          <div className="mb-3 form-check">
            <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
            <label className="form-check-label" htmlFor="exampleCheck1">I agree to the terms and conditions</label>
          </div>
          <button type="button" className="btn btn-theme my-4" to="upload-resume">
            Submit
          </button>
        </form>
      </section>
    </div>
  )
}
export default UploadResume
