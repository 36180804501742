import { useEffect, useState } from 'react'
import { isBrowser, isMobile } from 'react-device-detect'

const TalentFilter = (props) => {
  const [showApply, setShowApply] = useState(false)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('experience')) {
      const expValues = queryParams.get('experience')
      console.log('experience values from query param: ', expValues)
    }
  }, [])

  const productAttributes = [
    { name: 'Experience', field: 'experience', values: [{ t: 'Freshers', v: '0' }, { t: '0 - 1 year', v: '0-1' }, { t: '1-3', v: '1-3' }, { t: '3 - 5 years', v: '3-5' }, { t: '5 - 8 years', v: '5-8' }, { t: '8 - 12 years', v: '8-12' }, { t: '12 - 15 years', v: '12-15' }, { t: '15 years +', v: '15-60' }] },
    { name: 'Skills', field: 'skills', values: [{ t: 'Java', v: 'java' }, { t: '.net', v: '.NET' }, { t: 'React', v: 'react' }, { t: 'Angular', v: 'angular' }, { t: 'Web Designer', v: 'web designer' }] },
    { name: 'Notice Period', field: 'noticeperiod', values: [{ t: 'Immediate', v: 0 }, { t: '15 days', v: 15 }, { t: '30 days', v: 30 }, { t: '45 days', v: 45 }, { t: '60 days', v: 60 }, { t: '90 days', v: 90 }] },
    { name: 'Salary', field: 'ctc', values: [{ t: 'Less than 5 lakhs', v: '5' }, { t: 'Less than 10 lakhs', v: '10' }, { t: 'Less than 15 lakhs', v: '15' }, { t: 'Less than 20 lakhs', v: '20' }, { t: 'Less than 30 lakhs', v: '30' }, { t: 'Less than 50 lakhs', v: '50' }, { t: 'Less than 1 crore', v: '100' }] },
    { name: 'Qualification', field: 'qualification', values: [{ t: '12th', v: '12th' }, { t: 'BE/B.Tech/Bachelor', v: 'BE/B.Tech/Bachelor' }, { t: 'ME/M.Tech/MCA/Master', v: 'ME/M.Tech/MCA/Master' }] }
  ]
  //  {t: , v: }
  const groupBy = (array, property, value) => array.reduce((grouped, element) => ({
    ...grouped,
    [element[property]]: [...(grouped[element[property]] || []), element[value]]
  }), {})

  const handleChange = () => {
    var selectedAttr = []
    Array.prototype.map.call(document.querySelectorAll('.prod-attr input[type=checkbox]:checked'), (item) => {
      var attr = item.getAttribute('data-attr')
      var val = item.getAttribute('data-val')
      selectedAttr.push({ attr: attr, val: val })
    })
    setShowApply(selectedAttr.length > 0)

    const result = groupBy(selectedAttr, 'attr', 'val')
    setFilters(result)
  }
  const applyFilter = () => {
    props.onApplyFilter(filters)
    // props.onCancelFilter()
  }

  return (
    <div className={(props.show || isBrowser ? 'd-block' : 'd-none') + ' product-filter bg-white me-4 shadow-sm'}>
      <div className="filter-header bg-white py-3 px-4">
        <div className="d-flex">
          <h4 className="flex-grow-1">FILTERS</h4>
          {isBrowser &&
            <div>{showApply && <button onClick={applyFilter} className="btn btn-sm btn-theme">Apply</button>}</div>
          }
        </div>
      </div>
      <div className="filter-content mt-2 px-4">
        {
          productAttributes.map((item, index) =>
            <div key={'attr' + index}>
              <h6 className="fw-bold">{item.name}</h6>
              <ul className="prod-attr list-unstyled border-bottom pb-3" data-attr={item.name}>
                {
                  item.values.map((val, i) =>
                    <li key={'attrval' + i}>
                      <label>
                        <input type="checkbox" className="me-2" onChange={() => handleChange()} data-attr={item.field} data-val={val.v} />{val.t}
                      </label>
                    </li>
                  )}
              </ul>
            </div>
          )
        }
      </div>
      {isMobile &&
        <div className="p-3 fixed-bottom bg-white d-flex justify-content-between p-4">
          <button className="btn btm-sm btn-light" onClick={applyFilter}>Cancel</button>
          <button className="btn btm-sm btn-primary" onClick={applyFilter}>Apply</button>
        </div>
      }
    </div>

  )
}
export default TalentFilter
