import { configureStore } from '@reduxjs/toolkit'
import commonSlice from './slices/commonSlice'
import partnerSlice from './slices/partnerSlice'

export const store = configureStore({
  reducer: {
    partner: partnerSlice,
    common: commonSlice
  }
})
