import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { post } from '../../../util'

const Users = () => {
  const [users, setUsers] = useState([])

  // const clientsList = [
  //  { id: '1', username: 'izeetek', name: 'Izeetek Pvt Ltd', email: 'info@izeetek.com', mobile: '+919191919191', role: 'Administrator' },
  //  { id: '2', username: 'dhanpati', name: 'Dhanpati Sahu', email: 'dhanpati.sahu@izeetek.com', mobile: '+919191919191', role: 'Administrator' },
  //  { id: '3', username: 'zakir', name: 'Zakir', email: 'zakir@izeetek.com', mobile: '+919191919191', role: 'Manager' },
  //  { id: '4', username: 'anshuman', name: 'Anshuman', email: 'anshuman@izeetek.com', mobile: '+919191919191', role: 'Manager' },
  //  { id: '5', username: 'abhay', name: 'Abhay', email: 'abhay@izeetek.com', mobile: '+919191919191', role: 'HR' },
  //  { id: '6', username: 'anamika', name: 'Anamika', email: 'anamika@izeetek.com', mobile: '+919191919191', role: 'User' }
  // ]

  useEffect(() => {
    listUsers(process.env.REACT_APP_USER_LIST).then(data => {
      setUsers(data)
    }).catch(error =>
      console.error(`List users error: ${error}`)
    )
  }, [])

  const listUsers = async (url) => {
    const response = await post(url)
    return response.json()
  }

  return (
    <div>
      <section className="hero p-3 p-md-5">
        <div className="container d-flex">
          <div className="left-hero">
            <h1 className="fw-bold text-theme">Users</h1>
            <h5>Add, edit and manage Users</h5>
          </div>
        </div>
      </section>
      <section className="container shadow mb-4 resume-form" style={{ backgroundColor: '#fafafa' }}>
        <div className="d-flex mb-3">
          <div className="col-md-12 p-4 flex-grow-1">
            <div className="mb-4 d-flex align-items-center justify-content-between">
              <h5 className="flex-grow-1">
                Showing Users ({users.length})
              </h5>
              <div>
                <select>
                  <option>Most Recent</option>
                </select>
                <select className="ms-3">
                  <option>Show 20</option>
                </select>
              </div>
              <div className="ps-3">
                <Link to={'/recruiter/users/create'} className="btn btn-sm btn-outline-primary">Crate a user</Link>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>First Name</th><th>Last Name</th><th>Username</th><th>Mobile</th><th>Email</th><th>Role</th>
                </tr>
              </thead>
              <tbody>
                {
                  users.map((item) =>
                  (<tr key={'user' + item.id}>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td><Link to={`../recruiter/users/${item.id}`}>{item.username}</Link></td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.email}</td>
                    <td>{item.role}</td>
                    <td><Link className="btn btn-sm btn-outline-primary" to={`../recruiter/user/edit/${item.id}`}>Edit</Link></td>
                  </tr>)
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </section>

    </div>
  )
}
export default Users
