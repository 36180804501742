import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import './login.scss'
import { authorize } from '../../../redux/slices/commonSlice'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginStatus, setLoginStatus] = useState(null)
  const [loading, setLoading] = useState(false)

  const authenticate = async (e) => {
    e.preventDefault()
    await createToken()
  }

  const createToken = async () => {
    // Default options are marked with *
    setLoginStatus(null)
    setLoading(true)
    const data = { username: username, password: password }
    const response = await fetch(process.env.REACT_APP_AUTH_TOKEN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    if (response.ok) {
      setLoading(false)
      await response.json().then(data => {
        sessionStorage.setItem('authToken', data)
        // generateAntiForgeryToken()
        dispatch(authorize())
        navigate('/')
      }).catch(error => {
        console.error(`Authentication Error: ${error}`)
        setLoginStatus('Authentication Error')
      })
    } else {
      setLoading(false)
      response.status === 401 ? setLoginStatus('Invalid credential') : setLoginStatus(null)
    }
}

  // const generateAntiForgeryToken = async () => {
  //  var response = await fetch('/antiforgery/token', {
  //    method: 'GET',
  //    headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
  //  })

  //  if (response.ok) {
  //    // https://developer.mozilla.org/docs/web/api/document/cookie
  //    const xsrfToken = document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN=')).split('=')[1]

  //    response = await fetch('/JavaScript/FetchEndpoint', {
  //      method: 'POST',
  //      headers: { 'X-XSRF-TOKEN': xsrfToken, Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
  //    })

  //    if (response.ok) {
  //      resultElement.innerText = await response.text()
  //    } else {
  //      resultElement.innerText = `Request Failed: ${response.status}`
  //    }
  //  } else {
  //    resultElement.innerText = `Request Failed: ${response.status}`
  //  }
  // }

  return (
    <>
      <section className="hero p-3 p-md-5">
        <div className="container d-flex">
          <div className="left-hero">
            <h1 className="fw-bold mb-5"><span className="text-theme">Log in</span></h1>
          </div>
        </div>
      </section>
      <section className="container shadow p-5 resume-form bg-white">
        <form onSubmit={(e) => authenticate(e)}>
          <div className="row mb-3">
            <div className="col-md-4">
              <div className="mb-3">
                <label id="lblFirstName" htmlFor="txtFirstName" className="form-label">Username</label>
                <input type="text" className="form-control" id="txtFirstName" aria-describedby="lblFirstName" value={username} onChange={(e) => { setUsername(e.target.value) }} required />
              </div>
              <div className="mb-3">
                <label htmlFor="txtPassword" className="form-label">Password</label>
                <input type="password" className="form-control" id="txtPassword" value={password} onChange={(e) => { setPassword(e.target.value) } } required />
              </div>
              <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
              </div>
              <input type="submit" className="btn btn-theme my-4" value="Log in" />
              {loading &&
                <span className="ms-4">
                  Signing in...
                </span>
}
            </div>
          </div>
          {loginStatus &&
            <div className="p-2 border border-danger text-danger">{loginStatus}</div>
          }
        </form>
      </section>
    </>
  )
}
export default Login
