export const post = async (url, payload) => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
    },
    body: JSON.stringify(payload)
  })
}

export const get = async (url) => {
  return await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
    }
  })
}
