import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
// import axios from 'axios'
import TalentFilter from '../../filters/talentFilters'
import Pagination from '../../common/pagination'
import Toast from '../../common/toast'
import { get } from '../../../util'

const Talents = () => {
  // const [progress, setProgress] = useState(0)
  const [talents, setTalents] = useState([])
  const [query, setQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [jobId, setJobId] = useState('')
  const [searchParams] = useSearchParams()

  const [toastTitle, setToastTitle] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastColor] = useState(0)
  const [showToast, setShowToast] = useState(false)

  useEffect(() => {
    loadTalents()
  }, [currentPage])

  const searchHandler = () => {
    loadTalents()
  }

  const pageChangeHandler = (val) => {
    setCurrentPage(val)
    loadTalents()
  }

  const loadTalents = async () => {
    const jid = searchParams.get('jid') || ''
    setJobId(jid)
    listTalents(`${process.env.REACT_APP_TALENT_LIST}?` + new URLSearchParams({ pageNumber: currentPage, pageSize: 10, query: query, jid: jid, filters: searchParams })).then(data => {
      setTalents(data)
    }).catch(error =>
      console.error(`List Error: ${error}`)
    )
  }

  const listTalents = async (url) => {
    // Default options are marked with *
    const response = await get(url)
    if (response.ok) {
      return response.json()
    } else {
      if (response.status === 401) {
        setToastTitle('Unauthorized Access')
        setToastMessage('Please log in to authorize.')
      } else {
        setToastTitle(`Error ${response.status}`)
        setToastMessage('Server error')
      }
      setToastColor(-1)
      setShowToast(true)
    }
  }

  // const handleFileInput = (e) => {
  //  const files = e.target.files
  //  if (!files) {
  //    return
  //  }
  //  submitForm(files)
  // }

  const shortlist = async (jid, tid) => {
    const response = await fetch(process.env.REACT_APP_TALENT_SHORTLIST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
      },
      body: JSON.stringify({ jid: jid, tid: tid })
    })
    response.json().then(data => {

    }).catch(error =>
      console.error(`Shortlist Error: ${error}`)
    )
  }

  const queryStr = (args) => {
    return '?' + Object.entries(args).map(([key, value]) => {
      return key + '=' + encodeURIComponent(value)
    }).join('&').toLowerCase()
  }
  const applyFilterHandler = async (filters) => {
    console.log('Filters: ', filters)

    const _query = queryStr(filters)
    if (history.pushState) {
      var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + _query
      window.history.pushState({ path: newurl }, '', newurl)
      await loadTalents()
    }
  }

  // const submitForm = (files) => {
  //  const formData = new FormData()

  //  for (let i = 0; i < files.length; i++) {
  //    formData.append('files', files[i], files[i].name)
  //  }

  //  axios.post(process.env.REACT_APP_PROFILE_UPLOADS, formData, {
  //    onUploadProgress: (progressEvent) => {
  //      if (progressEvent.bytes) {
  //        setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
  //      }
  //    }
  //  })
  // }

  return (
    <div>
      {showToast && <Toast title={toastTitle} message={toastMessage} color={toastColor} onClose={() => { setShowToast(false) }} />}
      <section className="hero p-3 p-md-5">
        <div className="container d-flex align-items-center">
          <div className="left-hero">
            <h1 className="fw-bold">Search <span className="text-theme">Talents</span></h1>
            <h5>Search for talents ends here.</h5>
          </div>
          <div className="flex-grow-1 text-end">
            <Link className="btn btn-theme input-file me-3" to={ '../recruiter/talent/add' }>Add talent</Link>
            {/* <button className="btn btn-theme input-file"> */}
            {/*  Upload resumes */}
            {/*  <input type="file" id="fileResume" name="resume" multiple onChange={handleFileInput} accept=".doc,.docx,.pdf,.rtf,.txt,.csv" /> */}
            {/* </button> */}
          </div>
        </div>
        {
          // (progress !== 0 && progress !== 100) &&
          //  <div className="container my-3">
          //  <div className="progress-bar rounded text-center border">
          //    <div style={{ width: `${progress}%` }}>
          //    </div>
          //    <span>{progress}</span>
          //  </div>
          // </div>
        }
      </section>
      <section className="container shadow mb-4 resume-form" style={{ backgroundColor: '#fafafa' }}>
        <div className="d-flex mb-3">
          <div className="col-md-3 rounded">
            <TalentFilter onApplyFilter={ applyFilterHandler } />
          </div>
          <div className="col-md-9 p-4 flex-grow-1">
            {jobId && <div className="p-4 bg-white mb-3 shadow-sm">
              <h5 className="text-theme">Matching Profile(s)</h5>
            </div>}
            <div className="mb-4 d-flex flex-wrap justify-content-between align-items-center">
              <span className="h5">
                Talents
              </span>
              <div className="d-flex">
                <select className="form-select">
                  <option>Name</option>
                  <option>Experience</option>
                  <option>CTC</option>
                </select>
                <Pagination totalPage={100} onPageChange={(val) => pageChangeHandler(val) } />
              </div>
            </div>
            <div className="input-group py-3">
              <input type="search" className="form-control" onChange={(e) => setQuery(e.target.value)} onKeyPress={(e) => { const kc = e.keyCode || e.charCode; if (kc === 13) { document.getElementById('btn-search-talent').click() } }} />
              <button className="btn btn-outline-secondary" type="button" id="btn-search-talent" onClick={() => searchHandler() }>Search</button>
            </div>
            {
              talents?.map((item) =>
              (<div key={'talent' + item.id} className="card shadow-sm mb-4">
                <div>
                  <div className="d-flex p-3">
                    <div className="flex-grow-1">
                      <Link to={`../recruiter/talent/${item.id}`} className="h5 text-decoration-none text-theme">
                        {item.name}
                      </Link>
                    </div>
                    <div>
                      {jobId && <button type="button" className="btn btn-sm btn-outline-primary mx-3" onClick={() => { shortlist(jobId, item.id) } }>Shortlist...</button>}
                      <Link type="button" className="btn btn-sm btn-theme" to={`../recruiter/talent/edit/${item.id}`}>Edit</Link>
                    </div>
                  </div>
                  {/* <h6 className="text-muted">{item.role}</h6> */}
                  <div className="px-3 pb-3">
                    <span className="me-2">Skills: </span>{item.skills}
                  </div>
                  <div className="border-top p-3">
                    <small className="text-muted me-2">Experience: {item.experience}</small>
                  </div>
                  {/* <small className="text-muted">CTC: {item.ctc}.</small> */}
                </div>
                <div className="px-4 py-3 border-top d-flex align-items-center justify-content-between d-none">
                  <div className="flex-grow-1">
                    {/* <small className="text-muted">Noteice Period: {item.np}</small> */}
                  </div>
                </div>
              </div>)
              )
            }
          </div>
        </div>
      </section>
    </div>
  )
}
export default Talents
