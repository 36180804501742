import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Toast from '../common/toast'
import TalentFilter from '../filters/talentFilters'
import { get } from '../../util'

const Jobs = () => {
  const [jobs, setJobs] = useState([])

  const [toastTitle, setToastTitle] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastColor] = useState(0)
  const [showToast, setShowToast] = useState(false)

  useEffect(() => {
    listJobs(process.env.REACT_APP_JOB_LIST).then(data => {
      setJobs(data)
    }).catch(error =>
      console.error(`List Error: ${error}`)
    )
  }, [])

  const listJobs = async (url) => {
    const response = await get(url)
    if (response.ok) {
      return response.json()
    } else {
      if (response.status === 401) {
        setToastTitle('Unauthorized Access')
        setToastMessage('Please log in to authorize.')
      } else {
        setToastTitle(`Error ${response.status}`)
        setToastMessage('Server error')
      }
      setToastColor(-1)
      setShowToast(true)
    }
  }

  return (
    <div>
      {showToast && <Toast title={toastTitle} message={toastMessage} color={toastColor} onClose={() => { setShowToast(false) }} />}
      <section className="hero p-3 p-md-5">
        <div className="container d-flex align-items-center">
          <div className="left-hero">
            <h1 className="fw-bold">Search <span className="text-theme">jobs</span></h1>
            <h5>We will find a suitable job for your profile</h5>
          </div>
          <div className="flex-grow-1 text-end">
            <Link className="btn btn-theme" to={'../recruiter/post-job'}>Post Job</Link>
          </div>
        </div>
      </section>
      <section className="container bg-container shadow p-4 mb-4 resume-form">
        <form>
          <div className="d-flex mb-3">
            <div className="col-md-3 rounded">
              <TalentFilter />
            </div>
            <div className="col-md-9 ps-1 flex-grow-1">
              <div className="mb-4 d-flex justify-content-between">
                <h4>
                  JOBS
                </h4>
                <div>
                  <select>
                    <option>Most Recent</option>
                  </select>
                  <select className="ms-3">
                    <option>Show 20</option>
                  </select>
                </div>
              </div>
              <div style={{ overflowX: 'auto' }}>
                {
                  jobs.map((item, i) => (
                    <div key={`jobrow-${i}`} className="mb-3 bg-white">
                      <div className="p-3 mt-3">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <Link className="link-text fs-4" to={`../job/${item.id}`}>{item.title}</Link>
                          </div>
                          <div>{item.experience ? `Experience: ${item.experience} year(s)` : null}</div>
                        </div>
                        <div className="mt-3">
                          <span className="text-muted">Company:</span> {item.company}, <span className="text-muted">Client:</span> {item.client}
                        </div>
                      </div>
                      <div className="text-muted border-top p-3 small d-flex align-items-center">
                        <div className="flex-grow-1">Opening: {item.positions}{item.sortlisted ? `, Sortlisted: ${item.sortlisted}` : null}</div>
                        <div>
                          <Link type="button" className="btn btn-sm btn-theme me-4" to={`../recruiter/talents?jid=${item.id}`}>Find Talents</Link>
                          <Link type="button" className="btn btn-sm btn-primary" to={`../recruiter/job/edit/${item.id}`}>Edit</Link>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  )
}
export default Jobs
