import { createSlice } from '@reduxjs/toolkit'

export const PartnerSlice = createSlice({
  name: 'partnerSlice',
  initialState: {
    partnerPhones: []
  },
  reducers: {
    resetPartnerPhones: (state) => {
      state.partnerPhones = []
    },

    addPartnerPhones: (state) => {
      const newArray = [
        ...state.partnerPhones,
        { name: '', phone: '', email: '' }
      ]
      state.partnerPhones = newArray
    },

    handleRemovePartnerPhone: (state, action) => {
      const newArray = [...state.partnerPhones]
      const filtered = newArray.filter((item, index) => index !== action.payload)
      state.partnerPhones = filtered
    },

    handlePartnerPhoneChange: (state, action) => {
      const { name, value, index } = action.payload
      const list = [...state.partnerPhones]

      list[index][name] = value
      state.partnerPhones = list
    }
  }
})
export const {
  resetPartnerPhones,
  addPartnerPhones,
  handlePartnerPhoneChange,
  handleRemovePartnerPhone
} = PartnerSlice.actions
export default PartnerSlice.reducer
