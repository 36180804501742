import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import PartnerContact from '../../common/partnerContact'
import { addPartnerPhones, resetPartnerPhones } from '../../../../redux/slices/partnerSlice'
import { get, post } from '../../../util'

const Clients = () => {
  const [clients, setClients] = useState([])
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [about, setAbout] = useState('')
  const [url, setUrl] = useState('')
  const [update, setUpdate] = useState(false)

  const { partnerPhones } = useSelector((state) => state.partner)
  const dispatch = useDispatch()

  useEffect(() => {
    listClient(process.env.REACT_APP_PARTNER_LIST).then(data => {
      setClients(data)
    }).catch(error =>
        console.error(`List Error: ${error}`)
    )
  }, [])

  const listClient = async (url) => {
    // Default options are marked with *
    const response = await get(url)
    return response.json()
  }

  const createClient = async (url, data) => {
    // Default options are marked with *
    const response = await post(url, data)
    return response.json()
  }

  const handleCreateClient = () => {
    const payload = {
      id: id,
      name: name,
      about: about,
      url: url,
      contacts: partnerPhones
    }

    createClient(update ? process.env.REACT_APP_PARTNER_UPDATE : process.env.REACT_APP_PARTNER_ADD, payload).then(data => {
      if (update) {
        setClients(clients.map((item) => item.id === data.id ? data : item))
      } else {
        setClients([data, ...clients])
      }
      $('#btnCancel').click()
    }).catch(error =>
      console.error(`List Error: ${error}`)
    )
  }

  const handleAdd = (c) => {
    setUpdate(false)
    dispatch(resetPartnerPhones())
    setId('')
    setName('')
    setAbout('')
    setUrl('')

    $('#exampleModal').modal().show()
  }

  const handleEdit = (c) => {
    dispatch(resetPartnerPhones())
    setId(c.id)
    setUpdate(true)
    setName(c.name)
    setAbout(c.about)
    setUrl(c.url)

    $('#exampleModal').modal().show()
  }
  const handleCancel = () => {
    $('#exampleModal').modal().hide()
  }

  // const clients = [
  //  { id: '1', name: 'Capgemini', contact: { name: 'Zakir', email: 'zakir@capgemini.com', mobile: '+919191919191' } },
  //  { id: '2', name: 'Infosys', contact: { name: 'Zakir', email: 'zakir@capgemini.com', mobile: '+919191919191' } },
  //  { id: '3', name: 'Dell', contact: { name: 'Zakir', email: 'zakir@capgemini.com', mobile: '+919191919191' } },
  //  { id: '4', name: 'Cognigant', contact: { name: 'Zakir', email: 'zakir@capgemini.com', mobile: '+919191919191' } },
  //  { id: '5', name: 'HCL', contact: { name: 'Zakir', email: 'zakir@capgemini.com', mobile: '+919191919191' } },
  //  { id: '6', name: 'HUL', contact: { name: 'Zakir', email: 'zakir@capgemini.com', mobile: '+919191919191' } }
  // ]

  return (
    <div>
      <section className="hero p-3 p-md-5">
        <div className="container d-flex">
          <div className="left-hero">
            <h1 className="fw-bold text-theme">Clients</h1>
            <h5>Search for talents ends here.</h5>
          </div>
        </div>
      </section>
      <section className="container shadow mb-4 resume-form" style={{ backgroundColor: '#fafafa' }}>
        <div className="d-flex mb-3">
          <div className="col-md-12 p-4 flex-grow-1">
            <div className="mb-4 d-flex align-items-center justify-content-between">
              <h5 className="flex-grow-1">
                Showing Clients ({ clients.length })
              </h5>
              <div>
                <select>
                  <option>Most Recent</option>
                </select>
                <select className="ms-3">
                  <option>Show 20</option>
                </select>
              </div>
              <div className="ps-3">
                <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={ handleAdd }>Add a client...</button>
              </div>
            </div>
            {
              clients.map((item) =>
              (<div key={`client-${item.id}`} className="card shadow mb-4">
                <div className="p-4">
                  <h5 className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <Link to={`../recruiter/client/${item.id}`}>{item.name}</Link>
                    </div>
                    <div>
                      <button className="btn btn-sm btn-outline-primary me-3" onClick={() => handleEdit(item)}>Edit</button>
                      <Link className="btn btn-sm btn-theme" to={`../recruiter/post-job/${item.id}`}>Post a Job</Link>
                    </div>
                  </h5>
                  <div className="py-3 mt-3 border-top d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                      <span className="me-2">Jobs: {item.jobs}</span>
                      <span className="me-2">Openings: {item.openings}</span>
                      <span className="me-2">Sortlisted: {item.sortlisted}</span>
                    </div>
                  </div>
                </div>
              </div>)
              )
            }
          </div>
        </div>
      </section>

      <div className="modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">{update ? 'Edit Client' : 'Add a client'} </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel}></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="txtClientName" className="col-form-label">Client Name:</label>
                    <input type="text" className="form-control" id="txtClientName" value={name} onChange={(e) => setName(e.target.value) } />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="txtClientWebsite" className="col-form-label">Website:</label>
                    <input type="text" className="form-control" id="txtClientWebsite" value={url} onChange={(e) => setUrl(e.target.value)} />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="txtClientAbout" className="col-form-label">About:</label>
                  <textarea className="form-control" id="txtClientAbout" style={{ minHeight: '10rem' }} value={about} onChange={(e) => setAbout(e.target.value)}></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="txtClientAddress" className="col-form-label">Address:</label>
                  <textarea className="form-control" id="txtClientAddress"></textarea>
                  <button className="btn btn-sm btn-link">Add Address</button>
                </div>
                <PartnerContact />
              </form>
              <button className="btn btn-sm btn-link" onClick={() => { dispatch(addPartnerPhones()) }}>Add Contact</button>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" id="btnCancel" data-bs-dismiss="modal" onClick={ handleCancel }>Close</button>
              <button type="button" className="btn btn-primary" onClick={handleCreateClient}>{ update ? 'Update Client' : 'Add Client' }</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Clients
