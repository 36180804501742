const Steps = (props) => {
  return (
    <>
      <div className="steps d-flex mt-3">
        {
          props.events?.map((item) => (
            <div className="completed" title={item.name} onDoubleClick={() => alert(item.name)} key={`eventkey-${item.evtid}` }></div>
          ))}
      </div>
      <div>
      </div>
    </>
  )
}
export default Steps
