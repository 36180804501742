import { useState, useEffect } from 'react'
import { useParams, Link, useSearchParams } from 'react-router-dom'
import Steps from '../common/steps'
import { get } from '../../util'
import Toast from '../common/toast'

const Job = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()

  // const [id, setId] = useState('')
  const [company, setCompany] = useState('')
  const [client, setClient] = useState('')
  // const [employmentType, setEmploymentType] = useState(1)
  const [title, setTitle] = useState('')
  // const [jd, setJd] = useState('')
  const [skillsR, setSkillsR] = useState('')
  const [skillsO, setSkillsO] = useState('')
  const [noticePeriod, setNoticePeriod] = useState(0)
  const [experience, setExperience] = useState('')
  const [positions, setPositions] = useState(1)
  const [locations, setLocations] = useState('')
  const [qualification, setQualification] = useState('')
  const [ctc, setCTC] = useState('')
  const [talents, setTalents] = useState([])
  const [searchedTalents, setSearchedTalents] = useState([])
  const [jtid, setJtid] = useState(null)
  const [eventid, setEventId] = useState(null)
  const [note, setNote] = useState('')

  const [jobId, setJobId] = useState('')
  const [query, setQuery] = useState('')

  const [toastTitle, setToastTitle] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastColor] = useState(0)
  const [showToast, setShowToast] = useState(false)

  const [events] = useState([
    { t: 'Screening Feedback Pending', i: 'F62E0B14-D8DD-4DF4-A243-994FAEDBCC19' }, { t: 'Duplicate', i: 'DDCD5364-148E-4AC3-9C43-4D31CA13C7A7' }, { t: 'Screen Reject', i: '6835B61F-A840-467D-A4D1-97A716FF1792' },
    { t: 'Screen Select', i: '9674F902-6AEE-4668-AE5C-382971F4E0D0' }, { t: 'On Hold-NP', i: '61042ED9-9A25-4E80-9D8F-CF7687E7B083' }, { t: 'JWOO', i: '2C5755AF-26B4-4FC6-83C7-8E48F94F7273' },
    { t: 'L1 Required', i: 'E0EF56B5-9A53-44C3-90F2-C9CAD2095620' }, { t: 'L1 Processed', i: '87E81447-B5C7-4565-838E-2B12885807B4' }, { t: 'L1 Feedback Pending', i: '9FF6FDC9-1077-4548-8071-85453BA6AA80' },
    { t: 'L1 Reject', i: '9B90C5A3-4D23-4D0C-A544-560A9492534F' }, { t: 'L1 Select', i: '45FD5EA7-E306-47BF-A3C8-1247DB1B957C' }, { t: 'L2 Required', i: '9E4CBF3F-E572-47A6-87C6-85078D4F8922' },
    { t: 'L2 Processed', i: 'F766BECB-405E-4610-9DE2-C63F93DD097D' }, { t: 'L2 Feedback Pending', i: 'C528BFCD-0E44-4F92-AC7B-ABE4BDB30EDD' }, { t: 'L2 Reject', i: 'F16E4D86-0C3E-4C33-A05D-FE2376394880' },
    { t: 'L2 Select', i: '63162C08-9734-42F0-B124-8A7C1F6FB6A6' }, { t: 'L3 Required', i: '17595061-182A-4C70-8A77-513CC707A39F' }, { t: 'L3 Processed', i: '3D8B2926-9356-4771-8376-1C92DF20B8FB' },
    { t: 'L3 Feedback Pending', i: 'DC9C5009-2EC9-445B-969C-BCC15CF9E30C' }, { t: 'L3 Reject', i: '4441CA7C-2110-4254-BD56-C51C14FEA590' }, { t: 'L3 Select', i: '06DC8048-78D8-44F7-B6C5-FA69002399EE' },
    { t: 'L4 Required', i: '91D00798-3792-4BE2-AF5C-2670F70DEE1A' }, { t: 'L4 Processed', i: '6513A972-94A5-4AF8-BB92-743C217D17B4' }, { t: 'L4 Feedback Pending', i: '9A71BAA8-607B-467C-A51C-DF396F6AE3A2' },
    { t: 'L4 Reject', i: 'A496B78A-C0EB-4318-A87B-03C99D809CF6' }, { t: 'L4 Select', i: '26DEAAAB-8B7D-445E-90C7-C0A132F5BAA6' }, { t: 'Final Round Required', i: '0AC8F54B-4305-477C-9162-33C5B5AEB133' },
    { t: 'Final Round Processed', i: 'E430E9A8-FFE2-455A-8A3F-8F6DAA8EDF8F' }, { t: 'Final Round Feedback Pending', i: 'B322015D-0DA2-4047-A392-C3E5EAF41498' }, { t: 'Final Round Reject', i: '8004DF81-B56A-4DB3-8829-A1E29D8ED6F0' },
    { t: 'Final Round Select', i: '3155C100-9FC3-497E-A026-674DD52E59B0' }, { t: 'Not Interested', i: '3DC848E1-47CE-4EFA-A60F-1F7591C6B66C' }, { t: 'Requirement on hold', i: '71E97B53-B8DB-45B9-8681-DE4CE371D355' },
    { t: 'Joined', i: 'C83398A5-CDF5-4401-A468-905CA7964A62' }
  ])

  useEffect(() => {
    if (params.id) {
      setJobId(params.id)
      loadJob()
    }
  }, [])

  const loadJob = () => {
    getJob(`${process.env.REACT_APP_JOB_GET}?` + new URLSearchParams({ id: params.id, shortlisted: true })).then(data => {
      const job = data.job
      // setId(data.id)
      setCompany(job.company)
      setClient(job.client)
      setPositions(job.positions ? job.positions : 1)
      setLocations(job.locations)
      // setEmploymentType(data.employmentType ? data.employmentType : 1)
      setTitle(job.title)
      setSkillsR(job.skillsRequired)
      setSkillsO(job.skillsOptional)
      setNoticePeriod(job.noticePeriod)
      setExperience(job.experience)
      setQualification(job.qualification)
      setCTC(job.ctc)
      setTalents(JSON.parse(data.ats))
    }).catch(error =>
      console.error(`Get job error: ${error}`)
    )
  }

  const getJob = async (url) => {
    const response = await get(url)
    return response.json()
  }

  const removeShortlist = async (index, jid, tid) => {
    const response = await fetch(process.env.REACT_APP_TALENT_REMOVESHORTLIST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
      },
      body: JSON.stringify({ jid: jid, tid: tid })
    })
    response.json().then(data => {
      if (data) {
        setTalents(talents.filter((_, i) => i !== index))
      }
    }).catch(error =>
      console.error(`Shortlist Error: ${error}`)
    )
  }

  // Follow DRY principle for below code duplicate from talent
  const handleEdit = (jtid) => {
    setJtid(jtid)
  }

  const searchHandler = () => {
    loadTalents()
  }

  const loadTalents = async () => {
    // const jid = params.id || ''
    // setJobId(jid)
    listTalents(`${process.env.REACT_APP_TALENT_LIST}?` + new URLSearchParams({ pageNumber: 1, pageSize: 10, query: query, jid: '', filters: searchParams })).then(data => {
      setSearchedTalents(data)
    }).catch(error =>
      console.error(`List Error: ${error}`)
    )
  }

  const listTalents = async (url) => {
    // Default options are marked with *
    const response = await get(url)
    if (response.ok) {
      return response.json()
    } else {
      if (response.status === 401) {
        setToastTitle('Unauthorized Access')
        setToastMessage('Please log in to authorize.')
      } else {
        setToastTitle(`Error ${response.status}`)
        setToastMessage('Server error')
      }
      setToastColor(-1)
      setShowToast(true)
    }
  }

  const shortlist = async (jid, tid) => {
    const response = await fetch(process.env.REACT_APP_TALENT_SHORTLIST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
      },
      body: JSON.stringify({ jid: jid, tid: tid })
    })
    response.json().then(data => {

    }).catch(error =>
      console.error(`Shortlist Error: ${error}`)
    )
  }

  const addATS = async () => {
    const response = await fetch(process.env.REACT_APP_JOB_ADDATSEVENT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
      },
      body: JSON.stringify({ eventid: eventid, jtid: jtid, note: note })
    })
    response.json().then(data => {
      if (data) {
        setEventId('')
        setNote('')
        loadJob()
        $('#btnUpdateEventClose').click()
      }
    })
  }

  return (
    <>
      {showToast && <Toast title={toastTitle} message={toastMessage} color={toastColor} onClose={() => { setShowToast(false) }} />}
      <section className="hero p-3 p-md-5">
        <div className="container d-flex">
          <div className="left-hero">
            <h1 className="fw-bold text-theme">Job Details</h1>
          </div>
        </div>
      </section>
      <section className="container mb-4 resume-form">
        <div className="bg-white card p-4">
          {title &&
            <h5 className="fw-bold mb-4 text-theme">{title}</h5>
          }
          <div className="row">
            <div className="col-md-6 border">
              {company &&
                <div className="border-bottom row">
                  <div className="col-md-4 p-2 fw-bold">Company</div>
                  <div className="col-md-8 p-2">{company}</div>
                </div>
              }
              {client &&
                <div className="border-bottom row">
                  <div className="col-md-4 p-2 fw-bold">Client</div>
                  <div className="col-md-8 p-2">{client}</div>
                </div>
              }
              {skillsR &&
                <div className="border-bottom row">
                  <div className="col-md-4 p-2 fw-bold">Required Skills</div>
                  <div className="col-md-8 p-2">{skillsR}</div>
                </div>
              }
              {skillsO &&
                <div className="border-bottom row">
                  <div className="col-md-4 p-2 fw-bold">Optional Skills</div>
                  <div className="col-md-8 p-2">{skillsO}</div>
                </div>
              }
              {noticePeriod &&
                <div className="border-bottom row">
                  <div className="col-md-4 p-2 fw-bold">Notice Period</div>
                  <div className="col-md-8 p-2">{noticePeriod} days</div>
                </div>
              }
              {experience &&
                <div className="border-bottom row">
                  <div className="col-md-4 p-2 fw-bold">Experience</div>
                  <div className="col-md-8 p-2">{experience} years</div>
                </div>
              }
              {locations &&
                <div className="border-bottom row">
                  <div className="col-md-4 p-2 fw-bold">Locations</div>
                  <div className="col-md-8 p-2">{locations}</div>
                </div>
              }
              {positions &&
                <div className="border-bottom row">
                  <div className="col-md-4 p-2 fw-bold">Positions</div>
                  <div className="col-md-8 p-2">{positions}</div>
                </div>
              }
              {qualification &&
                <div className="border-bottom row">
                  <div className="col-md-4 p-2 fw-bold">Qualification</div>
                  <div className="col-md-8 p-2">{qualification}</div>
                </div>
              }
              {ctc &&
                <div className="border-bottom row">
                  <div className="col-md-4 p-2 fw-bold">CTC</div>
                  <div className="col-md-8 p-2">{ctc}</div>
                </div>
              }
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <div className="input-group pb-3">
                  <input type="search" className="form-control" onChange={(e) => setQuery(e.target.value)} placeholder="Search talent by name or email or mobile"
                    onKeyPress={(e) => { const kc = e.keyCode || e.charCode; if (kc === 13) { document.getElementById('btn-search-talent').click() } }} />
                  <button className="btn btn-outline-secondary" type="button" id="btn-search-talent" onClick={() => searchHandler()}>Search</button>
                </div>
                {
                  searchedTalents.map((item) =>
                  (
                    <div key={`stalent-${item.id}`} className="p-2 m-2 border-bottom d-flex align-items-center justify-content-between">
                      <div className="">
                        <div>{item.name}</div>
                        <small className="text-muted">{item.email}</small>
                      </div>
                      <div><button className="btn btn-sm btn-outline-primary" onClick={() => { shortlist(jobId, item.id) }}>Shortlist</button></div>
                    </div>
                  )
                  )}
              </div>
              {
                talents.map((item, index) =>
                (<div key={`eventkey-${item.jtid}`} className="card shadow-sm mb-3">
                  <div className="p-3">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <Link to={`../recruiter/talent/${item.t[0]?.id}`} className="h6 fw-bold text-decoration-none text-dark">
                          {item.t[0]?.name}
                        </Link>
                      </div>
                      <div>
                        <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => { handleEdit(item.jtid) }}>Update status</button>
                        <button className="btn btn-sm btn-danger ms-3" onClick={() => removeShortlist(index, params.id, item.t.id) }>Remove</button>
                      </div>
                    </div>
                    <Steps events={ item.t[0]?.events } />
                  </div>
                </div>)
                )
              }
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Update Tracking Status</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <select onChange={(e) => setEventId(e.target.value)}>
                <option value="">Select Status</option>
                {
                  events.map((item) => (
                    <option value={item.i} key={`eventmaster-${item.i}` }>{item.t}</option>
                  ))}
              </select>
              <div className="my-3">
                <label>Note:</label>
                <textarea className="w-100" onChange={(e) => setNote(e.target.value) }></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button id="btnUpdateEventClose" type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={() => addATS() }>Update</button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default Job
