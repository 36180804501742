import { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Toast from '../../common/toast'
import { get, post } from '../../../util'
import { AutoComplete } from '../../common/autoComplete'

const PostJob = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [id, setId] = useState('')
  const [company, setCompany] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [client, setClient] = useState('')
  const [clientId, setClientId] = useState('')
  const [employmentType, setEmploymentType] = useState(1)
  const [title, setTitle] = useState('')
  const [jd, setJd] = useState('')
  const [skillsR, setSkillsR] = useState('')
  const [skillsO, setSkillsO] = useState('')
  const [noticePeriod, setNoticePeriod] = useState('0')
  const [experience, setExperience] = useState('')
  const [positions, setPositions] = useState(1)
  const [locations, setLocations] = useState('')
  const [qualification, setQualification] = useState('')
  const [ctc, setCTC] = useState('')

  const [update, setUpdate] = useState(false)
  // const [clientSearch, setClientSearch] = useState('')
  const [showSuggestion, setShowSuggestion] = useState(false)
  const [showSuggestionCompany, setShowSuggestionCompany] = useState(false)

  const [toastTitle, setToastTitle] = useState('')
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastColor] = useState(0)
  const [showToast, setShowToast] = useState(false)

  useEffect(() => {
    if (params.id) {
      getJob(`${process.env.REACT_APP_JOB_GET}?` + new URLSearchParams({ id: params.id })).then(data => {
        const job = data.job
        setId(job.id)
        setCompany(job.company)
        setCompanyId(job.companyId)
        setClient(job.client)
        setClientId(job.clientId)
        setPositions(job.positions ? job.positions : 1)
        setLocations(job.locations)
        setEmploymentType(job.employmentType ? job.employmentType : 1)
        setTitle(job.title)
        setSkillsR(job.skillsRequired)
        setSkillsO(job.skillsOptional)
        setNoticePeriod(job.noticePeriod)
        setExperience(job.experience)
        setQualification(job.qualification)
        setCTC(job.ctc)
        setUpdate(true)
      }).catch(error =>
        console.error(`Get job error: ${error}`)
      )
    }
  }, [])

  const getJob = async (url, data) => {
    const response = await get(url)
    if (response.ok) {
      return response.json()
    } else {
      setToastColor(-1)
      setToastTitle(`Error ${response.status}`)
      setToastMessage('Server error')
      setShowToast(true)
    }
  }
  const createJob = async (url, data) => {
    const response = await post(url, data)
    if (response.ok) {
      return response.json()
    } else {
      setToastColor(-1)
      setToastTitle(`Error ${response.status}`)
      setToastMessage('Server error')
      setShowToast(true)
      return false
    }
  }

  const handleCreateJob = (e) => {
    e.preventDefault()
    const payload = {
      id: id,
      title: title,
      jd: jd,
      skillsRequired: skillsR,
      skillsOptional: skillsO,
      noticePeriod: noticePeriod,
      companyId: companyId,
      clientId: clientId,
      positions: parseInt(positions),
      locations: locations,
      employmentType: parseInt(employmentType),
      experience: experience,
      qualification: qualification,
      ctc: ctc
    }

    createJob(update ? process.env.REACT_APP_JOB_UPDATE : process.env.REACT_APP_JOB_ADD, payload).then(data => {
      // Navigate to Jobs
      if (data) {
        navigate('/jobs')
      }
    }).catch(error =>
      console.error(`List Error: ${error}`)
    )
  }

  const handlePositionChange = (v) => {
    if (v > 0) {
      setPositions(v)
    } else if (v === '') {
      setPositions(v)
    } else {
      setPositions(1)
    }
  }

  const onClientSelect = (selectedClient) => {
    setClientId(selectedClient.id)
    setClient(selectedClient.name)
    setShowSuggestion(false)
  }
  const onCompanySelect = (selectedCompany) => {
    setCompanyId(selectedCompany.id)
    setCompany(selectedCompany.name)
    setShowSuggestionCompany(false)
  }

  return (
    <div>
      {showToast && <Toast title={toastTitle} message={toastMessage} color={toastColor} onClose={() => { setShowToast(false) }} />}
      <section className="hero-client p-5">
        <div className="container d-flex">
          <div className="left-hero">
            <h1 className="fw-bold">Post <span className="text-theme">Job</span></h1>
            <h5>You can draft and publish a job</h5>
          </div>
        </div>
      </section>
      <section className="container shadow p-5 my-5 bg-white">
        <form onSubmit={(e) => handleCreateJob(e)}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label id="lblCompany" htmlFor="txtCompany" className="form-label">Payroll Company</label>
              <input readOnly type="text" className="form-control" id="txtCompany" aria-describedby="lblCompany" onFocus={() => setShowSuggestionCompany(true)} value={company} onChange={(e) => { setCompany(e.target.value) }} />
              {showSuggestionCompany &&
                <AutoComplete onClientSelect={(client) => onCompanySelect(client)} />
              }
            </div>
            <div className="col-md-6">
              <label id="lblClient" htmlFor="txtClient" className="form-label">Client</label>
              <input readOnly type="text" className="form-control" id="txtClient" aria-describedby="lblClient" onFocus={() => setShowSuggestion(true)} value={client} onChange={(e) => { setClient(e.target.value) }} />
              {showSuggestion &&
                <AutoComplete onClientSelect={(client) => onClientSelect(client)} />
              }
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label id="lblEmploymentType" htmlFor="ddlEmploymentType" className="form-label">Employment Type</label>
              <select className="form-select" id="ddlEmploymentType" aria-describedby="lblEmploymentType" value={ employmentType} onChange={(e) => { setEmploymentType(e.target.value) }} required>
                <option value="1">Permanent</option>
                <option value="2">Contract to Hire</option>
              </select>
            </div>
            <div className="col-md-6">
              <label id="lblPositions" htmlFor="txtPositions" className="form-label"># of Positions</label>
              <input type="number" className="form-control" id="txtPositions" aria-describedby="lblPositions" value={positions} onChange={(e) => handlePositionChange(e.target.value) } required />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label id="lblJobTitle" htmlFor="txtJobTitle" className="form-label">Job Title</label>
              <input type="text" className="form-control" id="txtJobTitle" aria-describedby="lblJobTitle" value={title} onChange={(e) => { setTitle(e.target.value) }} required />
            </div>
            <div className="col-md-6">
              <label id="lblJobLocation" htmlFor="txtJobLocation" className="form-label">Job Location</label>
              <input type="text" className="form-control" id="txtJobLocation" aria-describedby="lblJobLocation" value={locations} onChange={(e) => { setLocations(e.target.value) }} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label id="lblSkillsR" htmlFor="txtSkillsR" className="form-label">Skills (Required)</label>
              <input type="text" className="form-control" id="txtSkillsR" aria-describedby="lblSkillsR" value={skillsR} onChange={(e) => { setSkillsR(e.target.value) }} />
            </div>
            <div className="col-md-6">
              <label id="lblSkillsO" htmlFor="txtSkillsO" className="form-label">Skills (Optional)</label>
              <input type="text" className="form-control" id="txtSkillsO" aria-describedby="lblSkillsO" value={skillsO} onChange={(e) => { setSkillsO(e.target.value) }} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Notice Period</label>
              <select className="form-select" value={noticePeriod} onChange={(e) => { setNoticePeriod(e.target.value) }}>
                <option value="0">Immediate</option>
                <option value="15">15 days</option>
                <option value="30">30 days</option>
                <option value="45">45 days</option>
                <option value="60">60 days</option>
                <option value="90">90 days</option>
              </select>
            </div>
            <div className="col-md-3">
              <label id="lblExperience" htmlFor="txtExperience" className="form-label">Experience</label>
              <input type="text" className="form-control" id="txtExperience" aria-describedby="lblExperience" value={experience} onChange={(e) => { setExperience(e.target.value) }} />
            </div>
            <div className="col-md-3">
              <label id="lblQualification" htmlFor="txtQualification" className="form-label">Qualification</label>
              <input type="text" className="form-control" id="txtQualification" aria-describedby="lblQualification" value={qualification} onChange={(e) => { setQualification(e.target.value) }} />
            </div>
            <div className="col-md-3">
              <label id="lblCTC" htmlFor="txtCTC" className="form-label">CTC</label>
              <input type="text" className="form-control" id="txtCTC" aria-labelledby="lblCTC" value={ctc} onChange={(e) => { setCTC(e.target.value) }} />
            </div>
          </div>
          <div className="mb-3">
            <label id="lblJobDesc" htmlFor="txtJobDesc" className="form-label">Job Description</label>
            <textarea type="text" className="form-control" id="txtJobDesc" aria-describedby="lblJobDesc" value={jd} onChange={(e) => { setJd(e.target.value) }} style={{ minHeight: '10rem' }}></textarea>
          </div>
          <div className="form-buttons mt-5">
            <Link className="btn btn-primary me-4" to={'../jobs'}>Cancel</Link>
            <button className="btn btn-theme">
              Submit
            </button>
          </div>
        </form>
      </section>
    </div>
  )
}
export default PostJob
