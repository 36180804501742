import Header from '../../shared/header/header'
import Footer from '../../shared/footer/footer'
import { Outlet } from 'react-router-dom'

const RLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="my-2">
        <Outlet />
      </div>
      <Footer />
    </>
  )
}

export default RLayout
