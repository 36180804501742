import { useSelector, useDispatch } from 'react-redux'
import { handlePartnerPhoneChange } from '../../../redux/slices/partnerSlice'

const PartnerContact = () => {
  const dispatch = useDispatch()
  const { partnerPhones } = useSelector((state) => state.partner)

  return (
    <>
      <fieldset>
        <legend>Contact</legend>
        {
          partnerPhones.map((x, i) => (
            <div className="row mb-3" key={`partner-contact-${i}`}>
              <div className="col-md-4">
                <label htmlFor="txtClientHR" className="col-form-label">HR Person:</label>
                <input type="text" name="name" className="form-control" id="txtClientHR" onChange={(e) => dispatch(handlePartnerPhoneChange({ name: e.target.name, value: e.target.value, index: i }))} />
              </div>
              <div className="col-md-4">
                <label htmlFor="txtClientMobile" className="col-form-label">Mobile:</label>
                <input type="text" name="phone" className="form-control" id="txtClientMobile" onChange={(e) => dispatch(handlePartnerPhoneChange({ name: e.target.name, value: e.target.value, index: i }))} />
              </div>
              <div className="col-md-4">
                <label htmlFor="txtClientEmail" className="col-form-label">Email:</label>
                <input type="text" name="email" className="form-control" id="txtClientEmail" onChange={(e) => dispatch(handlePartnerPhoneChange({ name: e.target.name, value: e.target.value, index: i }))} />
              </div>
            </div>
          ))
        }
      </fieldset>
    </>
  )
}
export default PartnerContact
