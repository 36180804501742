import { createSlice } from '@reduxjs/toolkit'

export const CommonSlice = createSlice({
  name: 'commonSlice',
  initialState: {
    authorized: false
  },
  reducers: {
    logout: (state) => {
      if (sessionStorage?.getItem('authToken')) {
        sessionStorage.removeItem('authToken')
        state.authorized = false
      }
    },

    authorize: (state) => {
      if (sessionStorage?.getItem('authToken')) {
        state.authorized = true
      }
    },

    checkSession: (state) => {
      if (sessionStorage?.getItem('authToken')) {
        state.authorized = true
      }
    }
  }
})
export const {
  authorize,
  logout,
  checkSession
} = CommonSlice.actions
export default CommonSlice.reducer
